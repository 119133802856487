<template>
  <div>
    <b-table
      v-if="transactionsdetail.length && !isLoadingDetailTransactions"
      :items="transactionsdetail"
      :fields="tableColumns"
      :tbody-tr-class="rowClass"
      primary-key="id"
      :sort-by.sync="isSortBy"
      small
      :sort-desc.sync="isSortDir"
      class="position-relative mb-0"
    >
      <template #cell()="data">
        <span class="text-nowrap">
          {{ data.value}}
        </span>
      </template>

      <template #cell(createdate)="row">
        <span>{{showProperDates(row.item.createdate)}}</span>
      </template>
      <template #cell(operationdate)="row">
        <span>{{showProperDates(row.item.operationdate)}}</span>
      </template>
      <template #cell(canceldate)="row">
        <span>{{showProperDates(row.item.canceldate)}}</span>
      </template>
      <template #cell(userCancelName)="data">
        <span>{{data.value ? data.value : 'N/A'}}</span>
      </template>
      <template #cell(cancelNotes)="data">
        <span>{{data.value ? data.value : 'N/A'}}</span>
      </template>
    </b-table>

    <div class="text-center my-2" v-else-if="isLoadingDetailTransactions">
      <b-spinner class="align-middle"></b-spinner>
      <strong> Cargando Consumos</strong>
    </div>

    <div v-else>
      <b-alert variant="warning" :show="true" class="mb-0">
        <div class="alert-body"> <feather-icon icon="InfoIcon" class="mr-50" />
          <span>Haga una busqueda mediante el filtro</span>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { utils } from "@/modules/fivesClub/mixins/utils"


export default {
  mixins: [utils],
  components: {
    
  },
  props: {
    transactionsdetail: {
      type: Array,
      required: false,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      required: true,
    },
    isLoadingDetailTransactions: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
      optionsDateFormat: { weekday: "short", year: "numeric", month: "2-digit", day: "numeric" }, 
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState('fivesClubCatalogs', ['filter']),
  },

  methods: {
    showProperDates(date) {
      if (date) return new Date(date).toLocaleString("en-US", this.optionsDateFormat)
      else return 'N/A'
    }
  },
};
</script>

<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th  {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 9px;
  }
  .buttonInfo button{
    padding: 9px;
  }
  .buttonInfo {
    display: table-cell;
  }
  .isCentered{
    align-content: center;
    align-items: center;
  }
  .isSpinner{
    padding: 2rem;
  }
}
</style>
